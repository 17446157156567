import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import macBook from "../../images/macbook-01.png"
import PurchaseReportModal from "../PurchaseReportModal/PurchaseReportModal"
import Button from "../Button/Button"
import styles from "./IntroductionSection.module.css"

const IntroductionSection = () => (
  <section className={styles.container}>
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <h4 className={styles.heading}>Suburbgrowth.com.au</h4>
          <p>
            Most people want to know the value of real estate today, right? But
            wouldn’t it be better to know what the value is going to do over the
            next one, two or three years?
          </p>
          <p>
            Introducing suburbgrowth.com.au, a leading property forecasting tool
            that predicts a suburb's capital growth change over the next three
            years for either houses or units across Australia.
          </p>

          <div className="d-flex justify-content-center justify-content-sm-start">
            <PurchaseReportModal
              opener={<Button theme="dark">Buy Now</Button>}
            />
          </div>
        </div>

        <div className="col-lg-6 d-none d-lg-block">
          <img
            className={classNames("img-fluid", styles.image)}
            src={macBook}
            alt="Computer"
          />
        </div>
      </div>
    </div>
  </section>
)

const ListItem = ({ index, children }) => (
  <li className={styles.listItem}>
    <span className={styles.listItemMarker}>{index}</span>
    {children}
  </li>
)

ListItem.propTypes = {
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
}

export default IntroductionSection
