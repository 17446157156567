import React from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"

import styles from "./WhoIsItFor.module.css"

const WhoIsItFor = () => (
  <div className={styles.sectionWrapper}>
    <h2 className={styles.title}>Who is it for?</h2>
    <div className="container">
      <div>
        <p>
          Suburb Growth attempts to predict the future price movement in
          property values within a Suburb for either houses or units.
        </p>
        <p>
          This knowledge is invaluable for anyone interested in knowing what the
          general price for properties in a particular location will be in the
          next few years.
        </p>
        <p>Will prices grow or fall?</p>
        <p>By how much are they anticipated to grow or fall?</p>
        <p>
          So, whether you are a first home buyer right through to an experienced
          investor, looking to make your next purchasing decision or just
          checking-up on a property you already own – Suburbgrowth.com.au’s
          interactive Property Prediction Report should be essential knowledge
          for you.
        </p>
        <p>It’s a cost effective, yet “must have” resource for:</p>

        <ul className={classNames("row list-unstyled", styles.list)}>
          <li className="col-sm-6">
            <FontAwesomeIcon
              icon={faCheckCircle}
              className={styles.iconCheck}
            />
            <div>First Home Buyers - looking to buy well</div>
          </li>
          <li className="col-sm-6">
            <FontAwesomeIcon
              icon={faCheckCircle}
              className={styles.iconCheck}
            />
            <div>First Time &amp; Existing Investors - looking to buy well</div>
          </li>
          <li className="col-sm-6">
            <FontAwesomeIcon
              icon={faCheckCircle}
              className={styles.iconCheck}
            />
            <div>
              Upgraders &amp; Downgraders – timing their purchase and possible
              time to sell
            </div>
          </li>
          <li className="col-sm-6">
            <FontAwesomeIcon
              icon={faCheckCircle}
              className={styles.iconCheck}
            />
            <div>
              Experienced Investors - adding to their portfolio or checking-up
              on one they own
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
)

export default WhoIsItFor
