import React from "react"
import classNames from "classnames"

import SampleReportModal from "../SampleReportModal/SampleReportModal"
import Button from "../Button/Button"
import footerEbook from "../../images/property-prediction-report.png"
import heroBrandLogos from "../../images/hero-brand-logos-rev.png"
import styles from "./HeroSection.module.css"

const HeroSection = () => (
  <section className={styles.container}>
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <iframe
            className={styles.video}
            width="560"
            height="300"
            src="https://www.youtube.com/embed/srw-0jvlSDY"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Hero video"
          />
        </div>

        <div className="col">
          <h4 className={styles.heading}>
            What could your property be worth in 3 years from now?
          </h4>

          <div className="row">
            <div className="col-md-7 d-flex justify-content-center align-items-center justify-content-lg-start align-items-lg-start">
              <SampleReportModal
                opener={<Button theme="dark">Get Sample Report</Button>}
              />
            </div>
            <div className="col text-center">
              <img
                className={classNames("img-fluid", styles.reportImage)}
                src={footerEbook}
                alt="eBook"
              />
            </div>
          </div>
        </div>
      </div>

      <h3 className={styles.featuredInHeading}>Creator Featured In</h3>
      <img
        className="img-fluid"
        src={heroBrandLogos}
        alt="Featured in company logos"
      />
    </div>
  </section>
)

export default HeroSection
