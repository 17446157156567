import React from "react"
import SiteLayout from "../components/SiteLayout/SiteLayout"
import SEO from "../components/SEO/SEO"
import HeroSection from "../components/HeroSection/HeroSection"
import IntroductionSection from "../components/IntroductionSection/IntroductionSection"
import HowItWorks from "../components/HowItWorks/HowItWorks"
import WhoIsItFor from "../components/WhoIsItFor/WhoIsItFor"
import WhyUseIt from "../components/WhyUseIt/WhyUseIt"
import Pricing from "../components/Pricing/Pricing"
import ContactUs from "../components/ContactUs/ContactUs"
import styles from "./index.module.css"

const IndexPage = () => {
  return (
    <SiteLayout>
      <SEO title="Home" />
      <HeroSection />
      <IntroductionSection />
      <HowItWorks />
      <section className={styles.greyBackground}>
        <WhoIsItFor />
      </section>
      <WhyUseIt />
      <section className={styles.greyBackground}>
        <Pricing />
      </section>
      <ContactUs />
    </SiteLayout>
  )
}

export default IndexPage
